<template>
  <div class="getting-started">
    <Header v-bind:type="type" />
    <MainId />

    <div class="splash-bg">
      <div class="gradiant-bar"></div>
      <div class="splash-body">
        <div class="splash-content">
          <div class="splash-title container" role="main">
            <h1>Getting Started</h1>
            <h2>Personal Financial Statement</h2>

            <div class="grid-container">
              <div class="col1 grid-item">
                <div class="duration-msg">
                  <img class="classout-icon" srcset="@/assets/images/clock.png 1x, @/assets/images/clock-2x.png 2x"
                    src="@/assets/images/clock-2x.png" alt="" />
                  <span id="est-duration" class="splash-callout"><span class="est-label">Estimated Time to
                      Complete:</span> <span class="est-min">30 Minutes</span></span>
                </div>

              </div>
              <div class="col2 grid-item">
                <!-- day2 feature -->
                <!-- <div class="capsule-callout">
                <button type="button" class="capsule-msg" @click="loadPromoCodePage()">
                  <span class="capsule-text">Accepting a Pre-approved offer?</span>
                  <span class="capsule-link">Look up my offer</span>
                </button>
              </div> -->
              </div>
            </div>

          </div>

        </div>
      </div>
      <div class="splash-diagonal"></div>

    </div>

    <div class="ll-body">
      <div class="grid-container container">
        <div class="left-col">

          <div class="section-description">Thank you for your interest in pursuing a loan at FHB. Please complete this digital application to connect you with a member from our team to help you with your request.</div>

          <div class="panel-title">
            <!-- <img v-if="!panel_requiredDocs" @click="showRequiredDocs()" class="panel-icon" src="@/assets/images/caratDown.png" />
            <img v-if="panel_requiredDocs" @click="hideRequiredDocs()" class="panel-icon" src="@/assets/images/caratUp.png" /> -->
            <h2 class="panel-header">You will need the following information for all applicants</h2>
          </div>
          <div id="requireddocs" class="panel-content">
            <ul class="required-docs">
              <li>
                <img class="panel2-icon" srcset="@/assets/images/email.png 1x, @/assets/images/email-2x.png 2x"
                  src="@/assets/images/email-2x.png" alt="" />
                <span class="panel-txt">
                  Valid email address
                </span>
              </li>
              <li>
                <img class="panel2-icon" srcset="@/assets/images/ssn.png 1x, @/assets/images/ssn-2x.png 2x"
                  src="@/assets/images/ssn-2x.png" alt="" />
                <span class="panel-txt">
                  Federal Tax ID and/or Social Security Number
                </span>
              </li>
            </ul>
          </div>

          <!-- loan -->
          <div class="panel-title">
            <h2 class="panel-header">Please note</h2>
          </div>
          <ul id="guidelines_loan" class="guidelines">
            <li>You must be 18 years of age or older to apply.</li>
            <li>We currently accept online applications from residents of Hawaii, Guam, and the Commonwealth of the
              Northern Mariana Islands.</li>
            <li>You must allow First Hawaiian Bank to check your credit in order for us to consider you for a commercial
              loan.</li>
            <li>To protect your personal information, your application will timeout after 30 minutes of inactivity.</li>
          </ul>

          <!-- CTA -->
          <div class="cta-container">
            <div class="cta-btn-xl">
              <button type="button" class="cta-btn-xl-vert-align" @click="loadApplication()"
                data-test="nav-begin-application">Begin Application</button>
            </div>
          </div>

          <!-- disclaimers -->
          <div class="disclosure-bottom">
            <strong>IMPORTANT INFORMATION ABOUT PROCEDURES FOR OPENING A NEW ACCOUNT:</strong> To help the government
            fight the funding of terrorism and money laundering activities, Federal law requires all financial
            institutions to obtain, verify, and record information that identifies each person who opens an account.
            What this means for you: When you open an account, we will ask for your name, address, date of birth, and
            other information that will allow us to identify you. We may also ask to see your driver's license or other
            identifying documents.
          </div>

        </div>
        <div class="right-col">
          <div class="callout-box">
            <div class="callout-col1">
              <img srcset="@/assets/images/questionCircleIcon.png 1x, @/assets/images/questionCircleIcon-2x.png 2x"
                src="@/assets/images/questionCircleIcon-2x.png" alt="" />
            </div>
            <div class="callout-col2">
              <div class="callout-header">Got questions?</div>
              <div class="callout-line phone">Please email <a href="mailto:businessbanker@fhb.com">businessbanker@fhb.com</a>.</div>
            </div>
          </div>
        </div>

      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import Header from '@/components/Header'
import MainId from '@/components/MainId'
import Footer from '@/components/Footer'
import { mapActions, mapGetters } from 'vuex'
import jQuery from 'jquery'

export default {
  name: 'Getting Started Personal Fi',
  components: {
    Header,
    MainId,
    Footer
  },
  data () {
    return {
      alertMessage: '',
      panel_requiredDocs: true,
      panel_guidelines_loan: true,
      panel_guidelines_line: true
    }
  },
  computed: {
    ...mapGetters('personalFiStatement', ['type']),
  },
  created () {
    this.setType('loan')
  },
  mounted () {
    jQuery("html, body").animate({ scrollTop: 0 }, "slow");
  },
  methods: {
    ...mapActions('personalFiStatement', ['setType']),
    loadApplication () {
      this.$router.push({ name: 'PersonalFi Personal Info' })
    },
    // required docs
    showRequiredDocs() {
      jQuery( "#requireddocs" ).slideDown( "fast", function() {
        // Animation complete
      });
      this.panel_requiredDocs = true;
    },
    hideRequiredDocs() {
      jQuery( "#requireddocs" ).slideUp( "fast", function() {
        // Animation complete
      });
      this.panel_requiredDocs = false;
    },
    // loan
    showGuidelines_loan() {
      jQuery( "#guidelines_loan" ).slideDown( "fast", function() {
        // Animation complete
      });
      this.panel_guidelines_loan = true;
    },
    hideGuidelines_loan() {
      jQuery( "#guidelines_loan" ).slideUp( "fast", function() {
        // Animation complete
      });
      this.panel_guidelines_loan = false;
    },
    // line
    showGuidelines_line() {
      jQuery( "#guidelines_line" ).slideDown( "fast", function() {
        // Animation complete
      });
      this.panel_guidelines_line = true;
    },
    hideGuidelines_line() {
      jQuery( "#guidelines_line" ).slideUp( "fast", function() {
        // Animation complete
      });
      this.panel_guidelines_line = false;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/scss/_variables.scss";
@import "./src/scss/_mixins.scss";

.getting-started {
  outline: 1px dotted orange;

}
.grid-container {
  @include media-breakpoint-up(lg) {
    display: grid;
    grid-column-gap: 50px;
    grid-template-columns: 2fr 1fr;
    text-align: left;
    margin-top: 50px;
  }
}
.left-col {
  /* outline: 2px dotted red; */
}
.right-col {
  /* outline: 2px dotted green; */
}
.callout-box {
  background-color: $grayFooterBg;
  column-gap: 10px;
  display: flex;
  height: auto;
  margin-top: 1.5em;
  padding: 1.5em;

  @include media-breakpoint-up(lg) {
    margin-top: 0;
  }
}
.callout-col1 {
  text-align: right;

  img {
    height: auto;
    width: 15px;
  }
}
.callout-col2 {
}
.callout-header {
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
  text-align: left;
}
.callout-line {
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
}
.find-branch {
  margin-top: 1em;
}
a {
  color: $black;

  &:hover,
  &:focus,
  &:active {
    color: $redDark;
  }
}
.panel-title {
  display: block;

  .panel-header {
    margin-left: 0;
  }
}

.required-docs {
  margin: 0;
  padding: 0;
  list-style-type: none;

  li {
    margin-bottom: 20px;
  }
}
.panel-grid-top > div + div {
  margin-top: 1.5em;
}
.panel-grid-top > div,
.inc-ver {
  align-items: center;
  grid-column-gap: 1em;
  display: grid;
  grid-template-columns: 36px auto;
}
.inc-ver .panel2-icon {
  margin: auto;
  width: 26px;
}
.inc-ver + ul {
  list-style-type: disc;
  margin-top: 10px;
  margin-left: calc(36px + 1rem);
  padding-left: 32px;
}
.panel2-icon {
  height: auto;
  width: 36px;
  margin-right: 15px;
  vertical-align: middle;
}
.panel-row {
  margin-top: 1.5em;
}
.panel-txt,
ul li {
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
}
li + li {
  margin-top: 0.25em;
}
.panel-txt-super {
  line-height: 1.5;
}
.guidelines {
  margin-left: 0;
  padding-left: 32px;
}
li {
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
}
.cta-btn-xl-vert-align {
  align-items: center;
  width: 225px;
  height: 70px;
  background-color: $yellowLight;
  border: none;
  border-radius: 35px;
  display: flex;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 700;
  justify-content: center;
  line-height: 1.5;
  letter-spacing: 0.05em;
  margin: auto;
  text-align: center;
  text-transform: uppercase;

  &:hover,
  &:active,
  &:focus {
    background-color: $yellowHover;
    cursor: pointer;
    transition: background-color 300ms ease,300ms ease opacity;
  }
}
.cta-container {
  margin: 50px 0;
}
.ll-body {
  background-color: $white;
  position: relative;
  z-index: 20;
}
.splash-bg {
  overflow: hidden;
  position: relative;
  width: 100%;
  z-index: 1;
}
.gradiant-bar {
  height: 20px;
  background: linear-gradient(180deg, #F1F1F1 0%, rgba(255, 255, 255, 0) 100%);
  mix-blend-mode: multiply;
}
.splash-body {
  height: auto;
  background-color: $orangeFocus;
  background-image: url('../../../assets/images/orange@1x.png');
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: 1200px;
  margin-top: -20px;
  padding-bottom: 100px;
  position: relative;
  @include media-breakpoint-up(md) {
    padding-bottom: 140px;
  }
}
.splash-diagonal {
  background-image: url('../../../assets/images/white-bottom-left-corner.png');
  background-position: left bottom;
  background-repeat: no-repeat;
  background-size: 100% 60px;
  bottom: 0px;
  position: absolute;
  width: 100%;
  height: 60px;
  
  @include media-breakpoint-up(md) { // @media (min-width: 768px)
    background-size: 100% 100px;
    height: 100px;
  }
}
.splash-title {
  padding-top: 2.5em;
  @include media-breakpoint-up(sm) {
    padding-top: 75px;
  }
}
.splash-title .grid-container {
  margin-top: 1em;
}
.splash-callout {
  line-height: 1.5;
  font-feature-settings: 'cpsp' on;
  color: $black;
  padding-left: 10px;
}
h1 {
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.25;
  text-align: left;
  color: $black;
  text-align: left;
  margin: 0;

  @include media-breakpoint-up(lg) {
    font-size: 2.75rem;
  }
}
h2 {
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.25;
  text-align: left;
  color: $black;
  margin: 0.1em 0 0;

  @include media-breakpoint-up(lg) {
    font-size: 1.8rem;
  }
}
.grid-container {
  text-align: left;
  margin-top: 2em;
  position: relative;
  z-index: 20;
  
  @include media-breakpoint-up(md) {
    display: grid;
    grid-column-gap: 1em;
    //grid-template-columns: auto 420px;
  }
}
.ll-body .grid-container {
  margin-top: 0;
}
.duration-msg {
  display: flex;
  align-items: flex-start;
}
.duration-msg .splash-callout > span {
  display: inline;
}
.capsule-callout {
  box-sizing: border-box;
  display: inline-block;
  height: auto;
  width: auto;
  text-align: center;

  @include media-breakpoint-down(md) {
    margin-top: 1em;
  }
}
.capsule-msg {
  align-items: center;
  background: $white;
  border: 1px solid rgba(247, 147, 76, 0.16);
   border-radius: 35px;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5em;
  justify-content: center;
  min-height: 60px;
  padding: 0.5em 1.5em;

  &:hover,
  &:focus,
  &:active {
    background-color: fade-out($yellowHover, 0.5);
    cursor: pointer;
    transition: background-color 300ms ease,300ms ease opacity;

    .capsule-link {
      color: $black;
    }
  }
}
.capsule-text {
  font-style: normal;
  font-weight: normal;
  font-size: 0.95rem;
  line-height: 150%;
  color: $black;
}
.capsule-link {
  color: $red;
  font-size: 0.9rem;
  font-weight: 700;
}
.footer-container {
  height: 100px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  z-index: 2;
}
.classout-icon {
  height: auto;
  width: 18px;
}
</style>
